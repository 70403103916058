<template>
  <MasterDetail
    formTitle="Cadastro de Terceiros"
    :cols="cols"
    :opts="opts"
    :resourceUrl="resourceUrl"
    descriptionProperty="razao_social"
  ></MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
export default {
  components: {
    MasterDetail,
  },
  computed: {
    resourceUrl: function () {
      return `/v1/fiscal/terceiro/${this.getClient().clientId}`;
    },
    resourceEnquadramentoTerceiro: function () {
      return this.apiResource("/v1/fiscal/EnquadramentoTerceiro");
    },
  },
  created: function () {
    this.resourceEnquadramentoTerceiro.get().then((response) => {
      this.opts.enquadramento = response;
    });
  },
  data: function () {
    return {
      cols: [
        {
          key: "id",
          name: "ID",
          align: 1,
          hideInform: true,
        },
        {
          key: "razao_social",
          name: "Razão Social",
          type: this.$fieldTypes.TEXT,
          colSize: 7,
        },
        {
          key: "cnpj",
          name: "CNPJ",
          type: this.$fieldTypes.CNPJ,
          nowrap: true,
          colSize: 5,
        },
        {
          key: "nome",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          hideInform: (item) => item.enquadramentoId == 10,
          colSize: 7,
        },
        {
          key: "cpf",
          name: "CPF",
          type: this.$fieldTypes.CPF,
          nowrap: true,
          hideInform: (item) => item.enquadramentoId == 10,
          colSize: 5,
        },
        {
          key: "enquadramentoId",
          name: "Enquadramento",
          type: this.$fieldTypes.SELECT,
          rel: { to: "enquadramento", key: "id", name: "enquadramento" },
          rules: [{ rule: "required" }],
          colSize: 7,
        },
        {
          key: "criterio_rateio",
          name: "Critério Rateio",
          type: this.$fieldTypes.SELECT,
          rel: { to: "criterio_rateio", key: "id", name: "rateio" },
          rules: [{ rule: "required" }],
          hideInform: (item) => item.enquadramentoId == 10,
          colSize: 5,
        },
        {
          key: "cnae_primario",
          name: "CNAE Primário",
          type: this.$fieldTypes.TEXT,
          hideInform: (item) => item.enquadramentoId == 10,
          colSize: 12,
        },
        {
          key: "cnae_secundario",
          name: "CNAE Secundário",
          type: this.$fieldTypes.TEXTAREA,
          hideInform: (item) => item.enquadramentoId == 10,
          colSize: 12,
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ],
      opts: {
        enquadramento: [],
        criterio_rateio: [
          { id: 1, rateio: "Interno" },
          { id: 2, rateio: "Terceiro" },
        ],
      },
    };
  },
};
</script>
